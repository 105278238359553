import styled, { css } from 'styled-components'
import NextImage from 'next/image'
import { pxToRem } from '@style-space/components/src/lib/utils'
import { Anchor } from '@style-space/components/src/Anchor'
import { Heading } from '@style-space/components/src/Heading'

export const SNextImage = styled(NextImage)(
  () => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
)

export const Wrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: ${pxToRem(389)};
    overflow: hidden;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      height: max-content;
      max-width: 100%;
    }
  `,
)

export const SAnchor = styled(Anchor)`
  display: block;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  :hover {
    border-color: transparent;
  }
`

export const ImageWrap = styled.div(
  ({ theme }) => css`
    overflow: hidden;
    border-radius: ${theme.radius.small};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
)

export const InfoWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin: ${pxToRem(8, 0, 4)};
    font-size: ${pxToRem(14)};
    line-height: 1.5;
    color: ${theme.color.grey['500']};
  `,
)

export const Dott = styled.div(
  ({ theme }) => css`
    margin: ${pxToRem(0, 6)};
    border-radius: 50%;
    width: ${pxToRem(5)};
    height: ${pxToRem(5)};
    background: ${theme.color.grey['300']};
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      && {
        font-size: ${pxToRem(16)};
      }
    }
  `,
)
