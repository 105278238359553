import React from 'react'
import { pxToRem } from '@style-space/components/src/lib/utils'
import { RatioWrap } from '@style-space/components/src/RatioWrap'
import { useTheme } from 'styled-components'

import {
  Wrap,
  SAnchor,
  Title,
  ImageWrap,
  InfoWrap,
  Dott,
  SNextImage,
} from './ArticleItem.style'
import { Theme } from '../../themes'

export type Props = {
  className?: string
  href: string
  title: string
  category: string
  readTime: number
  src: string
}

export const ArticleItem: React.FC<Props> = ({
  className,
  href,
  title,
  category,
  readTime,
  src,
}) => {
  const {
    breakpoint: { tabletV, tabletH },
  } = useTheme() as Theme
  return (
    <Wrap className={`articleitem ${className}`} data-testid="articleitem">
      <SAnchor nextLink href={href} data-testid="articleitem-anchor">
        <RatioWrap ratio={75}>
          <ImageWrap>
            <SNextImage
              sizes={`(max-width: ${tabletV}) 100vw, (max-width: ${tabletH}) 50vw, ${pxToRem(
                400,
              )}`}
              src={src}
              alt=""
              fill
              data-testid="articleitem-image"
            />
          </ImageWrap>
        </RatioWrap>
        <InfoWrap data-testid="articleitem-info">
          {category}
          <Dott />
          {`${readTime} min read`}
        </InfoWrap>
        <Title headingType="style5" type="h3" data-testid="articleitem-title">
          {title}
        </Title>
      </SAnchor>
    </Wrap>
  )
}
ArticleItem.displayName = 'ArticleItem'
