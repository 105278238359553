import styled, { css } from 'styled-components'
import NextImage from 'next/image'
import { pxToRem } from '@style-space/components/src/lib/utils'
import { Anchor } from '@style-space/components/src/Anchor'
import { Heading } from '@style-space/components/src/Heading'
import { RatioWrap } from '@style-space/components/src/RatioWrap'

export const Wrap = styled.div(
  ({ theme }) => css`
    display: flex;
    background: ${theme.color.grey['100']};
    width: 100%;
    overflow: hidden;
    border-radius: ${theme.radius.small};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      flex-direction: column;
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${pxToRem(56)};
    padding: ${pxToRem(92, 56, 30, 0)};
    min-width: ${pxToRem(240)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      padding: ${pxToRem(30, 56, 30, 0)};
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding: ${pxToRem(24)};
      align-items: center;
      margin-left: 0;
    }
  `,
)

export const SAnchor = styled(Anchor)(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    text-decoration: none;
    :hover {
      border-color: none;
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      && {
        width: 100%;
        max-width: 100%;
      }
    }
  `,
)

export const InfoWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${pxToRem(4)};
    width: max-content;
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    color: ${theme.color.grey['500']};
  `,
)

export const Dott = styled.div(
  ({ theme }) => css`
    margin: ${pxToRem(0, 6)};
    border-radius: 50%;
    width: ${pxToRem(5)};
    height: ${pxToRem(5)};
    background: ${theme.color.grey['300']};
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(32)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-bottom: ${pxToRem(24)};
      && {
        font-size: ${pxToRem(24)};
      }
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin-bottom: ${pxToRem(24)};
      && {
        font-size: ${pxToRem(24)};
      }
    }
  `,
)

export const SRatioWrap = styled(RatioWrap)(
  ({ theme }) => css`
    &.fullscreen {
      display: block;
      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        display: none;
      }
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        display: block;
      }
    }
    &.tablet {
      display: none;
      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        display: block;
      }
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        display: none;
      }
    }
  `,
)

export const SNextImage = styled(NextImage)(
  () => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
)
