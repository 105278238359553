import React from 'react'
import styled, { css } from 'styled-components'
import { CircleLoader } from '@style-space/components/src/Loader/CircleLoader'
import { pxToRem } from '@style-space/components/src/lib/utils'

const Wrap = styled.div`
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
`

const InnerWrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    position: relative;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.875rem;
    row-gap: 6rem;

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      column-gap: 1.25rem;
      row-gap: 4.75rem;
      margin-bottom: 4.75rem;
      grid-template-columns: 1fr;
    }
  `,
)

const MoreWrap = styled.div(
  ({ theme }) => css`
    margin-top: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-top: 4.75rem;
    }
  `,
)

const MoreButton = styled.button(
  ({ theme }) => css`
    box-shadow: none;
    border-radius: ${theme.radius.small};
    color: ${theme.color.dark};
    line-height: 1;
    font-weight: ${theme.font.weight.bold};
    padding: 1rem 2rem;
    background-color: transparent;
    cursor: pointer;
    border: 0.0625rem solid ${theme.color.light};

    :hover {
      background-color: ${theme.color.light};
    }

    :focus,
    :active {
      border-color: ${theme.color.dark};
    }

    &.bookingGrid {
      color: ${theme.color.grey['500']};
      font-size: ${pxToRem(16)};
      line-height: 1;
      font-weight: ${theme.font.weight.medium};
      border: none;
      background-color: transparent;
      :hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  `,
)

const SThrobber = styled(CircleLoader)`
  display: block;
  margin: 0 auto;
`

type Props = {
  gridType?: 'bookingGrid'
  loading?: boolean
  onMore?(): void
  className?: string
  children: React.ReactNode
}

export const Grid: React.FC<Props> = ({
  className,
  gridType,
  loading,
  children,
  onMore,
}) => {
  return (
    <Wrap>
      <InnerWrap data-testid="grid" className={`grid-item-wrap ${className}`}>
        {children}
      </InnerWrap>
      {loading && <SThrobber />}
      {onMore && !loading && (
        <MoreWrap>
          <MoreButton
            className={`${className} ${gridType}`}
            onClick={onMore}
            data-testid="gridOnMore"
          >
            LOAD MORE
          </MoreButton>
        </MoreWrap>
      )}
    </Wrap>
  )
}
Grid.displayName = 'Grid'
