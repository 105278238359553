import React from 'react'
import { pxToRem } from '@style-space/components/src/lib/utils'
import { useTheme } from 'styled-components'

import {
  Wrap,
  SAnchor,
  Title,
  InfoWrap,
  Dott,
  InnerWrap,
  SRatioWrap,
  SNextImage,
} from './ArticleHighlight.style'
import { Theme } from '../../themes'

export type Props = {
  className?: string
  href: string
  title: string
  category: string
  readTime: number
  src: string
}

export const ArticleHighlight: React.FC<Props> = ({
  className,
  href,
  title,
  category,
  readTime,
  src,
}) => {
  const {
    breakpoint: { tabletV },
  } = useTheme() as Theme
  return (
    <Wrap
      className={`articleHighlight ${className}`}
      data-testid="articleHighlight"
    >
      <SRatioWrap ratio={79} className="tablet">
        <SNextImage
          fill
          sizes={`(max-width: ${tabletV}) 100vw, ${pxToRem(900)}`}
          src={src}
          alt=""
          data-testid="articleitem-image"
        />
      </SRatioWrap>
      <SRatioWrap ratio={54} className="fullscreen">
        <SNextImage
          fill
          sizes={`(max-width: ${tabletV}) 100vw, ${pxToRem(900)}`}
          src={src}
          alt=""
          data-testid="articleitem-image"
        />
      </SRatioWrap>
      <InnerWrap>
        <InfoWrap data-testid="articleHighlight-info">
          {category}
          <Dott />
          {`${readTime} min read`}
        </InfoWrap>
        <Title
          headingType="style3"
          type="h3"
          data-testid="articleHighlight-title"
        >
          {title}
        </Title>
        <SAnchor
          anchorType="button primary regular"
          uppercase
          nextLink
          href={href}
          data-testid="articleHighlight-anchor"
        >
          Read More
        </SAnchor>
      </InnerWrap>
    </Wrap>
  )
}
ArticleHighlight.displayName = 'ArticleHighlight'
