import { NextPage } from 'next'
import styled, { css } from 'styled-components'
import { Seo } from '@style-space/components/src/Seo'
import { pxToRem } from '@style-space/components/src/lib/utils'

import { getPostRoute } from '../utils/routes'
import { TopPanel } from './TopPanel'
import { ArticleHighlight } from './ArticleHighlight'
import { Grid } from './Grid'
import { ArticleItem } from './ArticleItem'

const Page = styled.div(
  ({ theme }) => css`
    max-width: ${theme.breakpoint.laptop};
    margin: 5.5rem auto;
    padding: ${pxToRem(20, 20)};
    .articleHighlight {
      margin-bottom: ${pxToRem(72)};
    }
  `,
)

const ArticlesWrap = styled.div`
  position: relative;
  min-height: 100vh;
  padding-bottom: 10rem;
`

type Props = {
  data: any
}

const Home: NextPage<Props> = ({ data }) => {
  const isHighlight = Boolean(data.results[0])
  const lastPost = data.results[0]
  const dataRest = data.results.slice(1, data.results.length)
  const highlightTitle = lastPost?.properties?.Title?.title?.[0]?.plain_text
  const highlightImage =
    lastPost?.properties?.Featured?.files?.[0]?.file.url ||
    lastPost?.properties?.Cover?.files[0]?.file.url
  const highlightSlug = lastPost?.properties?.slug?.rich_text?.[0]?.plain_text
  const highlightCategory = data.results[0]?.properties?.Category?.select?.name
  const highlightTime =
    lastPost?.properties?.readTime?.rich_text?.[0]?.plain_text

  console.log(data)
  return (
    <>
      <Page>
        <TopPanel />
        <Seo title={`Style Space || Blog`} />
        {isHighlight && (
          <ArticleHighlight
            href={getPostRoute(highlightSlug)}
            src={highlightImage}
            title={highlightTitle}
            category={highlightCategory}
            readTime={highlightTime}
          />
        )}
        <ArticlesWrap>
          <Grid>
            {dataRest.map((item: any) => {
              const slug = item.properties.slug.rich_text[0]?.plain_text
              const title = item.properties.Title.title[0]?.plain_text
              const coverUrl = item.properties.Cover.files[0]?.file.url
              const category = item.properties.Category.select.name
              const readTime = item.properties.readTime.rich_text[0]?.plain_text

              if (!slug || !title) {
                return null
              }

              return (
                <ArticleItem
                  key={slug}
                  href={getPostRoute(slug)}
                  src={coverUrl}
                  title={title}
                  category={category}
                  readTime={readTime}
                />
              )
            })}
          </Grid>
        </ArticlesWrap>
      </Page>
    </>
  )
}
Home.displayName = 'Home'

export default Home
